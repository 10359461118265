
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from './Pages/Home';
import UploadDND from './Components/UploadDND';
import Login from './Pages/Login';
function App() {
  return (
<div>
  <BrowserRouter>
    <Routes>
    <Route path='/login' element={<Login/>}/>
        <Route path='/' element={<Home/>}>
        {/* <Route path='/dnd' element={<UploadDND/>}/> */}
       
  
        </Route>
      </Routes> 
  </BrowserRouter>
</div>
  );
}

export default App;
