import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';

const CountSent = () => {

  const getFirstDayOfMonth = () => {
    const date = new Date();
    date.setDate(1);
    return date;
  };

 
  const getLastDayOfMonth = () => {
    const date = new Date();
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    return date;
  };

 
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const [serviceName, setServiceName] = useState("Fitofyy");
  const [data, setData] = useState([]); 
  const [error, setError] = useState(null); 

  const url = '/servicelogs';

  useEffect(() => {
    handleSubmit();
  }, []);

  const handleSubmit = async () => {
    if (!startDate || !endDate || !serviceName) {
      alert('Please select start date, end date, and a service');
      return;
    }

    const formatDate = (date) => {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-');
    };

    const data = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      serviceName
    };

    console.log("dates", data);

    try {
      const response = await axios.post(url, data);
      console.log("data", response.data.data);
 
      setData(response.data.data);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data. Please try again.');
    }
  };

  return (
    <div>
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
        <p className='text-lg md:text-3xl text-green-500 font-bold text-center'>SERVICE LOGS</p>
          <div className="flex py-2 flex-col md:flex-row justify-center gap-5">
            <div id="date-range-picker" className="flex items-center justify-center">
              <div className="relative md:mr-4">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderText="FROM"
                />
              </div>
              <span className="mx-4 text-gray-500">to</span>
              <div className="relative">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholderText="TO"
                />
              </div>
            </div>

            <div>
              <form className="max-w-sm mx-auto">
                <select
                  id="countries"
                  value={serviceName}
                  onChange={(e) => setServiceName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="Fitofyy">Fitofyy</option>
                  <option value="Gameofyy">Gameofyy</option>
                  <option value="Kidszonepro">Kidszonepro</option>
                </select>
              </form>
            </div>
          </div>

          <div className="mt-5 flex justify-center">
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </div>

          <div className="mt-5">
            {error && <p className="text-red-500">{error}</p>}
            <div className="relative overflow-x-auto">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-center text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      DATE
                    </th>
                    <th scope="col" className="px-6 py-3">
                   TOTAL SUB
                    </th>
                    <th scope="col" className="px-6 py-3">
                 PAID
                    </th>
                    <th scope="col" className="px-6 py-3">
                   REN
                    </th>
                  </tr> 
                </thead>
                <tbody className='text-center'>
                  {data.length > 0 ? (
                    data.map((item, index) => (
                      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {item.DATE}
                        </th>
                        <td className="px-6 py-4">{item.TotalSub}</td>
                        <td className="px-6 py-4">{item.Paid}</td> 
                        <td className="px-6 py-4">{item.REN}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="px-6 py-4 text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountSent;
