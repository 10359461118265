import React, { useState } from 'react';
import axios from 'axios';

const UploadDND = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const [openDropdown, setOpenDropdown] = useState(null); // State to manage which dropdown is open
  
    const handleFileChange = (event) => {
      setSelectedFile(event.target.files[0]);
    };
  
    const handleFileUpload = async () => {
      if (!selectedFile) {
        setUploadStatus('Please select a file first.');
        return;
      }
  
      const formData = new FormData();
      formData.append('file', selectedFile);
  
      try {
        const response = await axios.post('/getFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Response", response.data)
        setUploadStatus('File uploaded successfully!');
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadStatus('Failed to upload file.');
      }
    };
  
    const toggleDropdown = (dropdownName) => {
      setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
    };
  return (
    <div>
          <div>
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <div className="flex justify-center">
            <div className="flex flex-col md:flex md:flex-row justify-center gap-5">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">
                  Upload file
                </label>
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                />
              </div>

           

              {uploadStatus && <p className="mt-2 text-sm">{uploadStatus}</p>}
            </div>
          </div>

          <button
            onClick={handleFileUpload}
            className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded"
          >
            Upload
          </button>
        </div>
      </div>
    </div>
    </div>
  )
}

export default UploadDND
